import React from 'react';
import ReactDOM from 'react-dom';
import Search from './components/Search.js';
import ContactForm from "./components/ContactForm";
import Cta from "./components/Cta";


document.addEventListener("DOMContentLoaded", () => {

	document.body.style.setProperty('--vh', `${window.innerHeight / 100}px`);


	let doc = document;
	let body = document.body;
	let search = doc.getElementById('search');
	let contactForm = doc.getElementById('contact-form');
	let wantOfferButton = doc.querySelectorAll('.want-offer-event');
	let hashLinks = doc.querySelectorAll('a[href^="#"]');
	let ctaBanner = doc.querySelector('.cta-banner');
	let hamburger = doc.getElementById('hamburger');

	hashLinks.forEach((item) => {
		item.addEventListener('click', (e) => {
			let hash = item.getAttribute('href');
			history.pushState({}, '', '/' + hash)
			e.preventDefault();
			window.scrollTo({
				top: findPos(document.querySelector(hash)),
				behavior: 'smooth'
			});
		});
	});

	function findPos(obj) {
		let curtop = 0;
		if (obj.offsetParent) {
			do {
				curtop += obj.offsetTop;
			} while (obj = obj.offsetParent);
			return curtop;
		}
	}

	wantOfferButton.forEach((item) => {
		item.addEventListener('click', (e) => {
			doc.getElementById('contact-form').classList.add('open');
		})
	});


	hamburger.addEventListener('click', () => {
		body.classList.toggle('open-menu');
	})

	if (ctaBanner) {
		ReactDOM.render(<Cta locale={ctaBanner.getAttribute('data-locale')} />, ctaBanner)
	}

	if (search) {
		ReactDOM.render(<Search />, search);
	}

	if (contactForm) {
		ReactDOM.render(<ContactForm />, contactForm);
	}

	window.addEventListener("scroll", function (e) {
		let scroll = this.scrollY || document.documentElement.scrollTop;

		if (scroll > 82) {
			body.classList.add('sticky-menu');
		} else {
			body.classList.remove('sticky-menu');
		}

	});
	window.addEventListener('resize', () => {
		document.body.style.setProperty('--vh', `${window.innerHeight / 100}px`);
	});

	window.addEventListener('orientationchange', () => {
		document.body.style.setProperty('--vh', `${window.innerHeight / 100}px`);
	});
});
