import React, {Component} from 'react';
import ListItem from "./ListItem";

class Items extends Component {

    constructor(props) {
        super(props);
        this.state = {
            subOpen: false,
        }
    }

    componentDidMount() {
        if (this.props.item['subOptionsDropdownIsOpen']) {
            this.setState({
                subOpen: this.props.item['subOptionsDropdownIsOpen']
            })
        }

    }

    openSubmenu() {
        this.setState(prevState => ({
            'subOpen': !prevState.subOpen
        }));
    }

    changeOnTrue(id, topItem) {
        if (topItem['id'] === id) {
            topItem['checked'] = true;
            this.changeOnTrue(null, topItem);
        } else {
            if (topItem['subOptions']) {
                topItem['subOptions'].forEach((item) => {
                    if (item.id === id || id === null) {
                        item['checked'] = true;
                        if (item['subOptions']) {
                            this.changeOnTrue(null, item);
                        }
                    } else {
                        if (item['subOptions']) {
                            this.changeOnTrue(id, item);
                            let allTrue = true;
                            item['subOptions'].forEach((subItem) => {
                                if (!subItem['checked']) {
                                    allTrue = false;
                                    return;
                                }
                            });
                            if (allTrue) {
                                item['checked'] = true;

                            }
                        }
                    }
                });

                let allTrue = true;
                topItem['subOptions'].forEach((item) => {
                    if (!item['checked']) {
                        allTrue = false;
                        return;
                    }
                });
                if (allTrue) {
                    topItem['checked'] = true;
                }
            }
        }

        return topItem;

    }


    onChange(e, obj) {
        const {desParentItem} = this.props;
        let target = e.target;
        let items = desParentItem;
        if (target) {
            if (target.checked) {
                let returnItems = this.changeOnTrue(obj.id, items);
                this.props.setValues(returnItems);
            } else {
                let returnItems = this.props.changeOnFalse(obj.id, items);
                this.props.setValues(returnItems);
            }
        }
    }

    controlChecked(item, checked) {
        let check = false;
        if (checked) {
            checked.forEach((checkedItem) => {
                if (checkedItem.id === item.id) {
                    check = true;
                }
                if (checkedItem['subOptions'] && !check) {
                    if(this.controlChecked(item, checkedItem['subOptions'])){
                        check = true;
                    }
                }

            });
        }
        return check;
    }


    render() {
        const {item, desParentItem, checked} = this.props;
        const {subOpen} = this.state;
        let check = this.controlChecked(item, checked);


        let subOptionsItems = [];
        if (item['subOptions']) {
            subOptionsItems = item['subOptions'].map((sub, subKey) => {
                return (
                    <Items
                        setValues={(e) => this.props.setValues(e)}
                        item={sub}
                        subKey={subKey}
                        desParentItem={desParentItem}
                        checked={checked}
                        changeOnFalse={(id, topItem) => this.props.changeOnFalse(id, topItem)}
                    />
                );
            });
        }
        return (
            <li>
                <ListItem
                    onChange={(e) => this.onChange(e, item)}
                    item={item}
                    checked={check}
                />
                {
                    (subOptionsItems.length > 0 &&
                        <button className={this.state.subOpen ? 'open' : ''}
                                onClick={() => this.openSubmenu()}></button>)
                }
                <ul className={subOpen ? 'open sub-items' : 'sub-items'}>
                    {subOptionsItems}
                </ul>
            </li>
        );
    }
}

export default Items;