import React, {useRef, useImperativeHandle, forwardRef, useState} from 'react';

const Accommodation = forwardRef((props,ref) => {

    useImperativeHandle(
        ref,
        () => ({
            controlValid() {
                valid();
            }
        }),
    );


    const adults = useRef();
    const typeRoom = useRef();

    const valid = () => {
        let valid = true;

        adults.current.parentElement.classList.remove('error');
        typeRoom.current.parentElement.classList.remove('error');

        if(adults.current.value == '0') {
            adults.current.parentElement.classList.add('error');
            valid = false;
        }

        if(typeRoom.current.value == '0') {
            typeRoom.current.parentElement.classList.add('error');
            valid = false;
        }

        props.setValidForm(valid);
    }


    return (
        <div>
            <div className="detail-offer">
                <p><strong>Kam to bude: </strong>{props.data.destination.join(', ')}</p>
                <p>
                    <strong>Termín: </strong>{props.data.calendar['from']} - {props.data.calendar['to']}
                </p>
            </div>
            <div className="detail-offer">
	            <div className="form-group">
		            <label className="form-label" htmlFor="destination_accommodation">Adresa v blízkosti ktorej hľadáte ubytovanie</label>
		            <input className="form-control" type="text" name="destination_accommodation" id="destination_accommodation"
		                   onChange={(e) => props.onChange(e)} />
	            </div>

	            <div className="form-group">
		            <label htmlFor="accommodation_far_address" className="form-label">Maximálna vzdialenosť od ubytovania</label>
		            <select onChange={(e) => props.onChange(e)} className="form-control" name="accommodation_far_address" id="accommodation_far_address">
			            <option value="0" selected>Vyberte</option>
			            <option selected={props.data['accommodation_far_address'] == '1'} value="1">1 km</option>
			            <option selected={props.data['accommodation_far_address'] == '2'} value="2">2 km</option>
			            <option selected={props.data['accommodation_far_address'] == '3'} value="3">3 km</option>
			            <option selected={props.data['accommodation_far_address'] == '4'} value="4">4 km</option>
			            <option selected={props.data['accommodation_far_address'] == '5'} value="5">5 km</option>
			            <option selected={props.data['accommodation_far_address'] == '6'} value="6">6 km</option>
			            <option selected={props.data['accommodation_far_address'] == '7'} value="7">7 km</option>
			            <option selected={props.data['accommodation_far_address'] == '8'} value="8">8 km</option>
			            <option selected={props.data['accommodation_far_address'] == '9'} value="9">9 km</option>
			            <option selected={props.data['accommodation_far_address'] == '10'} value="10">10 km</option>
		            </select>

		            <span className="warning-text">Toto pole je povinné</span>

	            </div>

            </div>
            <div className="detail-offer">
                <div className="form-group">
                    <label htmlFor="room" className="form-label">Typ izby</label>
                    <select ref={typeRoom} onChange={(e) => props.onChange(e)} className="form-control" name="room" id="room">
                        <option value="0" selected>Vyberte</option>
                        <option selected={props.data['room'] == 'single'} value="single">single</option>
                        <option selected={props.data['room'] == 'double'} value="double">double</option>
                        <option selected={props.data['room'] == 'twin'} value="twin">twin</option>
                        <option selected={props.data['room'] == 'triple'} value="triple">triple</option>
                    </select>
                    <span className="warning-text">Toto pole je povinné</span>

                </div>

                <div className="form-group">
                    <label htmlFor="adults" className="form-label">Počet dospelých osôb</label>
                    <select ref={adults} onChange={(e) => props.onChange(e)} className="form-control" name="adults" id="adults">
                        <option value="0" selected>Vyberte</option>
                        <option selected={props.data['adults'] == '1'} value="1">1</option>
                        <option selected={props.data['adults'] == '2'} value="2">2</option>
                        <option selected={props.data['adults'] == '3'} value="3">3</option>
                        <option selected={props.data['adults'] == '4'} value="4">4</option>
                        <option selected={props.data['adults'] == '5'} value="5">5</option>
                        <option selected={props.data['adults'] == '6'} value="6">6</option>
                        <option selected={props.data['adults'] == '7'} value="7">7</option>
                        <option selected={props.data['adults'] == '8'} value="8">8</option>
                        <option selected={props.data['adults'] == '9'} value="9">9</option>
                        <option selected={props.data['adults'] == '10'} value="10">10</option>
                    </select>

                    <span className="warning-text">Toto pole je povinné</span>

                </div>
                <div className="row">
                    <div className="form-group col-xl-6">
                        <label htmlFor="babies" className="form-label">Počet detí do 2 rokov:</label>
                        <select onChange={(e) => props.onChange(e)} className="form-control" name="babies" id="babies">
                            <option value="0" selected>Vyberte</option>
                            <option selected={props.data['babies'] == '1'} value="1">1</option>
                            <option selected={props.data['babies'] == '2'} value="2">2</option>
                            <option selected={props.data['babies'] == '3'} value="3">3</option>
                            <option selected={props.data['babies'] == '4'} value="4">4</option>
                            <option selected={props.data['babies'] == '5'} value="5">5</option>
                            <option selected={props.data['babies'] == '6'} value="6">6</option>
                            <option selected={props.data['babies'] == '7'} value="7">7</option>
                            <option selected={props.data['babies'] == '8'} value="8">8</option>
                            <option selected={props.data['babies'] == '9'} value="9">9</option>
                            <option selected={props.data['babies'] == '10'} value="10">10</option>
                        </select>
                    </div>


                    <div className="form-group col-xl-6">
                        <label htmlFor="childs" className="form-label">Počet detí od 2 rokov do 14 rokov:</label>
                        <select onChange={(e) => props.onChange(e)} className="form-control" name="childs" id="childs">
                            <option value="0" selected>Vyberte</option>
                            <option selected={props.data['childs'] == '1'} value="1">1</option>
                            <option selected={props.data['childs'] == '2'} value="2">2</option>
                            <option selected={props.data['childs'] == '3'} value="3">3</option>
                            <option selected={props.data['childs'] == '4'} value="4">4</option>
                            <option selected={props.data['childs'] == '5'} value="5">5</option>
                            <option selected={props.data['childs'] == '6'} value="6">6</option>
                            <option selected={props.data['childs'] == '7'} value="7">7</option>
                            <option selected={props.data['childs'] == '8'} value="8">8</option>
                            <option selected={props.data['childs'] == '9'} value="9">9</option>
                            <option selected={props.data['childs'] == '10'} value="10">10</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="detail-offer">


                <div className="form-group">
                    <label htmlFor="category-accommodation" className="form-label">Kategórie ubytovania:</label>
                    <select onChange={(e) => props.onChange(e)} className="form-control" name="category-accommodation"
                            id="category-accommodation">
                        <option value="0" selected>Vyberte</option>
                        <option selected={props.data['category-accommodation'] == 'Hostel'} value="Hostel">Hostel
                        </option>
                        <option selected={props.data['category-accommodation'] == 'Penzión'} value="Penzión">Penzión
                        </option>
                        <option selected={props.data['category-accommodation'] == 'Botel'} value="Botel">Botel</option>
                        <option selected={props.data['category-accommodation'] == 'Hotel*'} value="Hotel*">Hotel *
                        </option>
                        <option selected={props.data['category-accommodation'] == 'Hotel**'} value="Hotel**">Hotel **
                        </option>
                        <option selected={props.data['category-accommodation'] == 'Hotel***'} value="Hotel***">Hotel
                            ***
                        </option>
                        <option selected={props.data['category-accommodation'] == 'Hotel****'} value="Hotel****">Hotel
                            ****
                        </option>
                        <option selected={props.data['category-accommodation'] == 'Hotel*****'} value="Hotel*****">Hotel
                            *****
                        </option>
                    </select>
                </div>
                <div className="form-group">
                    <h3>Strava</h3>
                    <div className="row">
                        <label className="form-label col-md-4 d-flex align-items-center" htmlFor="food-0"><input
                            id="food-0"
                            onChange={(e) => props.onChange(e)}
                            checked={props.data['food'] == 'raňajky'}
                            className="mr-2" type="radio" name="food" value="raňajky" /> Raňajky</label>
                        <label className="form-label col-md-4 d-flex align-items-center" htmlFor="food-1"><input
                            id="food-1"
                            onChange={(e) => props.onChange(e)}
                            checked={props.data['food'] == 'polpenzia'}
                            className="mr-2" type="radio" name="food" value="polpenzia" /> Polpenzia</label>
                        <label className="form-label col-md-4 d-flex align-items-center" htmlFor="food-2"><input
                            id="food-2"
                            onChange={(e) => props.onChange(e)}
                            checked={props.data['food'] == 'plná penzia'}
                            className="mr-2" type="radio" name="food" value="plná penzia" /> Plná penzia</label>
                        <label className="form-label col-md-4 d-flex align-items-center" htmlFor="food-3"><input
                            id="food-3"
                            onChange={(e) => props.onChange(e)}
                            checked={props.data['food'] == 'all inclusive'}
                            className="mr-2" type="radio" name="food" value="all inclusive" /> All inclusive</label>
                        <label className="form-label col-md-4 d-flex align-items-center" htmlFor="food-4"><input
                            id="food-4"
                            onChange={(e) => props.onChange(e)}
                            checked={props.data['food'] == 'bez stravy'}
                            className="mr-2" type="radio" name="food" value="bez stravy" /> Bez stravy</label>
                    </div>
                </div>

                <div className="form-group">
                    <h3>Služby</h3>
                    <div className="row">
                        <label className="form-label col-md-4 d-flex align-items-center" htmlFor="parking"><input
                            id="parking"
                            onChange={(e) => props.onChange({
                                name: e.target.name,
                                value: !props.data['parking']
                            })}
                            checked={props.data['parking']}
                            className="mr-2" type="checkbox" name="parking" value="1" /> Parkovanie</label>
                        <label className="form-label col-md-4 d-flex align-items-center" htmlFor="internet"><input
                            id="internet"
                            onChange={(e) => props.onChange({
                                name: e.target.name,
                                value: !props.data['internet']
                            })}
                            checked={props.data['internet']}
                            className="mr-2" type="checkbox" name="internet" value="1" /> Internet</label>
                        <label className="form-label col-md-4 d-flex align-items-center" htmlFor="baby-bed"><input
                            id="baby-bed"
                            onChange={(e) => props.onChange({
                                name: e.target.name,
                                value: !props.data['baby-bed']
                            })}
                            checked={props.data['baby-bed']}
                            className="mr-2" type="checkbox" name="baby-bed" value="1" /> Detská postieľka</label>
                        <label className="form-label col-md-4 d-flex align-items-center" htmlFor="extra-bed"><input
                            id="extra-bed"
                            onChange={(e) => props.onChange({
                                name: e.target.name,
                                value: !props.data['extra-bed']
                            })}
                            checked={props.data['extra-bed']}
                            className="mr-2" type="checkbox" name="extra-bed" value="1" />Prísteľka</label>
                    </div>
                </div>

            </div>
        </div>
    );

});

export default Accommodation;
