import React, {useRef} from 'react';

const ContactData = (props) => {

	const fullname = useRef(null);
	const email = useRef(null);
	const phone = useRef(null);
	const text = useRef(null);

	const validateEmail = (email) => {
		let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	const valid = (e) => {
		let valid = true;
		if (props.onClick)
			props.onClick.current.controlValid();

		fullname.current.parentElement.classList.remove('error');
		email.current.parentElement.classList.remove('error');
		phone.current.parentElement.classList.remove('error');
		text.current.parentElement.classList.remove('error');

		if (fullname.current.value == '') {
			fullname.current.parentElement.classList.add('error');
			valid = false;
		}

		if (!validateEmail(email.current.value)) {
			email.current.parentElement.classList.add('error');
			valid = false;
		}
		if (phone.current.value == '') {
			phone.current.parentElement.classList.add('error');
			valid = false;
		}

		if (text.current.value == '') {
			text.current.parentElement.classList.add('error');
			valid = false;
		}

		props.setValidForm(valid);
	}

	return (
		<div className="form">
			<h3 className="text-center">Kontaktné údaje</h3>
			<div className="row">
				<div className="col-sm-6 form-group">
					<label htmlFor="fullname" className="form-label">Meno a priezvisko</label>
					<input ref={fullname}
					       onChange={(e) => props.onChange(e)}
					       value={props.data['fullname']}
					       id="fullname"
					       name="fullname"
					       type="text" className="form-control" />
					<span className="warning-text">Toto pole je povinné</span>
				</div>

				<div className="col-sm-6 form-group">
					<label htmlFor="company"
					       className="form-label">Názov firmy</label>
					<input id="company"
					       onChange={(e) => props.onChange(e)}
					       value={props.data['company']}
					       name="company"
					       type="text"
					       className="form-control" />
					<span className="warning-text">Toto pole je povinné</span>
				</div>
			</div>

			<div className="row">
				<div className="col-sm-6 form-group">
					<label htmlFor="email" className="form-label">E-mail</label>
					<input ref={email}
					       onChange={(e) => props.onChange(e)}
					       value={props.data['email']}
					       id="email"
					       name="email"
					       type="text"
					       className="form-control" />
					<span
						className="warning-text">Zadajte správny tvar emailu</span>
				</div>

				<div className="col-sm-6 form-group">
					<label htmlFor="phone"
					       className="form-label">Telefón</label>
					<input ref={phone}
					       onChange={(e) => props.onChange(e)}
					       value={props.data['phone']}
					       id="phone"
					       name="phone"
					       type="text"
					       className="form-control" />
					<span className="warning-text">Toto pole je povinné</span>
				</div>
			</div>

			<div className="row">
				<div className="col-sm-12 form-group">
					<label htmlFor="texte" className="form-label">Správa</label>
					<textarea ref={text}
					          onChange={(e) => props.onChange(e)}
					          className="form-control"
					          name="text"
					          id="text">{props.data['text']}</textarea>
					{/*<p className="note">* Ak uvediete Vašu konkrétnu predstavu,
						náš
						predajca sa bude vedieť lepšie nachystať</p>*/}
				</div>
			</div>
			<div className="text-center">
				<button onClick={() => valid()} type="submit">Chcem ponuku</button>
			</div>
		</div>
	);

};

export default ContactData;
