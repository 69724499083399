import React, {forwardRef, useImperativeHandle, useReducer, useRef, useState} from 'react';
import DayPickerInput from "react-day-picker/DayPickerInput";
import 'react-day-picker/lib/style.css';

import MomentLocaleUtils, {formatDate, parseDate} from "react-day-picker/moment";
import moment from "moment";

const CarRent = forwardRef((props, ref) => {
    useImperativeHandle(
        ref,
        () => ({
            controlValid() {
                valid();
            }
        }),
    );

    const carPickupPlace = useRef();
    const carReturnPlace = useRef();
    const rentFromDateGroup = useRef();
    const rentToDateGroup = useRef();
    const driverName = useRef();
    const driverAge = useRef();

    const valid = () => {
        let valid = true;

        carPickupPlace.current.parentElement.classList.remove('error');
        carReturnPlace.current.parentElement.classList.remove('error');
        driverAge.current.parentElement.classList.remove('error');
        driverName.current.parentElement.classList.remove('error');
        rentFromDateGroup.current.classList.remove('error');
        rentToDateGroup.current.classList.remove('error');



        if (carPickupPlace.current.value == '') {
            carPickupPlace.current.parentElement.classList.add('error');
            valid = false;
        }

        if (carReturnPlace.current.value == '') {
            carReturnPlace.current.parentElement.classList.add('error');
            valid = false;
        }

        if (props.data['rentFromDate'] == '' || !props.data['rentFromDate']) {
            rentFromDateGroup.current.classList.add('error');
            valid = false;
        }

        if (props.data['rentToDate'] == '' || !props.data['rentToDate']) {
            rentToDateGroup.current.classList.add('error');
            valid = false;
        }

        if (driverAge.current.value == '') {
            driverAge.current.parentElement.classList.add('error');
            valid = false;
        }

        if (driverName.current.value == '') {
            driverName.current.parentElement.classList.add('error');
            valid = false;
        }

        props.setValidForm(valid);
    }

    const updateFromDate = (day, {selected, disabled}) => {
        if (disabled) {
            return;
        }
        if (props.data['rentToDate']) {
            let diff = moment(day).startOf('day').diff(props.data['rentToDate'], 'days');
            if (diff <= 0) {
                props.onChange({
                    'name': 'rentFromDate',
                    'value': moment(day).format('YYYY-MM-DD')
                });
            }
        } else {
            props.onChange({
                'name': 'rentFromDate',
                'value': moment(day).format('YYYY-MM-DD')
            });
        }

    }
    const updateToDate = (day, {selected, disabled}) => {
        if (disabled) {
            return;
        }

        if (props.data['rentFromDate']) {
            let diff = moment(day).startOf('day').diff(props.data['rentFromDate'], 'days');
            if (diff > 0) {
                props.onChange({
                    'name': 'rentToDate',
                    'value': moment(day).format('YYYY-MM-DD')
                });
            }
        } else {
            props.onChange({
                'name': 'rentToDate',
                'value': moment(day).format('YYYY-MM-DD')
            });
        }

    }
    const isDisabled = (date, type) => {
        if (date > new Date(props.data['rentToDate']) && type === 'from') { // after days as FROM DATE
            return date;
        } else if (date < new Date(moment(props.data['rentFromDate']).add(1, 'days').format('YYYY-MM-DD')) && props.data['rentFromDate'] && type === 'to') { // before days as TO DATE
            return date;
        }
    }

    return (
        <>
            <div className="detail-offer">
                <div className="row">
                    <div className="col-xl-6 form-group">
                        <label htmlFor="" className="form-label">Miesto prevzatia vozidla</label>
                        <input ref={carPickupPlace}
                               value={props.data['carPickupPlace']}
                               onChange={(e) => props.onChange(e)}
                               className="form-control"
                               type="text"
                               name='carPickupPlace'
                               placeholder="Miesto prevzatia vozidla" />

                        <span className="warning-text">Toto pole je povinné</span>

                    </div>
                    <div className="col-xl-6 form-group">
                        <label htmlFor="" className="form-label">Miesto vrátenia vozidla</label>
                        <input ref={carReturnPlace}
                               value={props.data['carReturnPlace']}
                               onChange={(e) => props.onChange(e)}
                               className="form-control"
                               type="text"
                               name='carReturnPlace'
                               placeholder="Miesto vrátenia vozidla" />

                        <span className="warning-text">Toto pole je povinné</span>

                    </div>
                </div>
            </div>
            <div className="detail-offer">
                <div className="row">
                    <div className="form-group col-xl-6" ref={rentFromDateGroup}>
                        <label htmlFor="date-from" className="form-label">Dátum prevzatia</label>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            value={props.data['rentFromDate'] ? new Date(props.data['rentFromDate']) : ''}
                            defaultValue={''}
                            placeholder={'DD. MM. YYYY'}
                            dayPickerProps={{
                                onDayClick: (day, state) => updateFromDate(day, state),
                                selectedDays: props.data['rentFromDate'] ? new Date(props.data['rentFromDate']) : '',
                                localeUtils: MomentLocaleUtils,
                                locale: 'sk',
                                disabledDays: day => isDisabled(day, 'from')

                            }}
                            inputProps={{
                                className: 'form-control',
                                id: "date-from",
                                name: "date-from",
                                readonly: "readonly"
                            }}
                        />
                        <span className="warning-text">Toto pole je povinné</span>

                    </div>
                    <div className="form-group col-xl-6" ref={rentToDateGroup}>
                        <label htmlFor="date-to" className="form-label">Dátum vrátenia</label>
                        <DayPickerInput
                            formatDate={formatDate}
                            parseDate={parseDate}
                            value={props.data['rentToDate'] ? new Date(props.data['rentToDate']) : ''}
                            placeholder={'DD. MM. YYYY'}
                            dayPickerProps={{
                                onDayClick: (day, state) => updateToDate(day, state),
                                selectedDays: props.data['rentToDate'] ? new Date(props.data['rentToDate']) : '',
                                localeUtils: MomentLocaleUtils,
                                locale: 'sk',
                                disabledDays: day => isDisabled(day, 'to')
                            }}
                            inputProps={{
                                className: 'form-control',
                                id: "date-to",
                                name: "date-to",
                                readonly: "readonly"
                            }}
                        />
                        <span className="warning-text">Toto pole je povinné</span>
                    </div>
                </div>

                <div className="row">
                    <div className="form-group col-xl-6">
                        <label htmlFor="" className="form-label">Čas prevzatia auta:</label>
                        <select onChange={(e) => props.onChange(e)} className="form-control" name="carPickupTime" id="">
                            <option value="0" selected>Vyberte</option>
                            <option selected={props.data['carPickup'] === '0:00'} value="0:00">0:00</option>
                            <option selected={props.data['carPickup'] === '1:00'} value="1:00">1:00</option>
                            <option selected={props.data['carPickup'] === '2:00'} value="2:00">2:00</option>
                            <option selected={props.data['carPickup'] === '3:00'} value="3:00">3:00</option>
                            <option selected={props.data['carPickup'] === '4:00'} value="4:00">4:00</option>
                            <option selected={props.data['carPickup'] === '5:00'} value="5:00">5:00</option>
                            <option selected={props.data['carPickup'] === '6:00'} value="6:00">6:00</option>
                            <option selected={props.data['carPickup'] === '7:00'} value="7:00">7:00</option>
                            <option selected={props.data['carPickup'] === '8:00'} value="8:00">8:00</option>
                            <option selected={props.data['carPickup'] === '9:00'} value="9:00">9:00</option>
                            <option selected={props.data['carPickup'] === '10:00'} value="10:00">10:00</option>
                            <option selected={props.data['carPickup'] === '11:00'} value="11:00">11:00</option>
                            <option selected={props.data['carPickup'] === '12:00'} value="12:00">12:00</option>
                            <option selected={props.data['carPickup'] === '13:00'} value="13:00">13:00</option>
                            <option selected={props.data['carPickup'] === '14:00'} value="14:00">14:00</option>
                            <option selected={props.data['carPickup'] === '15:00'} value="15:00">15:00</option>
                            <option selected={props.data['carPickup'] === '16:00'} value="16:00">16:00</option>
                            <option selected={props.data['carPickup'] === '17:00'} value="17:00">17:00</option>
                            <option selected={props.data['carPickup'] === '18:00'} value="18:00">18:00</option>
                            <option selected={props.data['carPickup'] === '19:00'} value="19:00">19:00</option>
                            <option selected={props.data['carPickup'] === '20:00'} value="20:00">20:00</option>
                            <option selected={props.data['carPickup'] === '21:00'} value="21:00">21:00</option>
                            <option selected={props.data['carPickup'] === '22:00'} value="22:00">22:00</option>
                            <option selected={props.data['carPickup'] === '23:00'} value="23:00">23:00</option>
                        </select>
                    </div>

                    <div className="form-group col-xl-6">
                        <label htmlFor="" className="form-label">Čas vrátenia auta:</label>
                        <select onChange={(e) => props.onChange(e)} className="form-control" name="carReturnTime" id="">
                            <option value="0" selected>Vyberte</option>
                            <option selected={props.data['carReturnTime'] === '0:00'} value="0:00">0:00</option>
                            <option selected={props.data['carReturnTime'] === '1:00'} value="1:00">1:00</option>
                            <option selected={props.data['carReturnTime'] === '2:00'} value="2:00">2:00</option>
                            <option selected={props.data['carReturnTime'] === '3:00'} value="3:00">3:00</option>
                            <option selected={props.data['carReturnTime'] === '4:00'} value="4:00">4:00</option>
                            <option selected={props.data['carReturnTime'] === '5:00'} value="5:00">5:00</option>
                            <option selected={props.data['carReturnTime'] === '6:00'} value="6:00">6:00</option>
                            <option selected={props.data['carReturnTime'] === '7:00'} value="7:00">7:00</option>
                            <option selected={props.data['carReturnTime'] === '8:00'} value="8:00">8:00</option>
                            <option selected={props.data['carReturnTime'] === '9:00'} value="9:00">9:00</option>
                            <option selected={props.data['carReturnTime'] === '10:00'} value="10:00">10:00</option>
                            <option selected={props.data['carReturnTime'] === '11:00'} value="11:00">11:00</option>
                            <option selected={props.data['carReturnTime'] === '12:00'} value="12:00">12:00</option>
                            <option selected={props.data['carReturnTime'] === '13:00'} value="13:00">13:00</option>
                            <option selected={props.data['carReturnTime'] === '14:00'} value="14:00">14:00</option>
                            <option selected={props.data['carReturnTime'] === '15:00'} value="15:00">15:00</option>
                            <option selected={props.data['carReturnTime'] === '16:00'} value="16:00">16:00</option>
                            <option selected={props.data['carReturnTime'] === '17:00'} value="17:00">17:00</option>
                            <option selected={props.data['carReturnTime'] === '18:00'} value="18:00">18:00</option>
                            <option selected={props.data['carReturnTime'] === '19:00'} value="19:00">19:00</option>
                            <option selected={props.data['carReturnTime'] === '20:00'} value="20:00">20:00</option>
                            <option selected={props.data['carReturnTime'] === '21:00'} value="21:00">21:00</option>
                            <option selected={props.data['carReturnTime'] === '22:00'} value="22:00">22:00</option>
                            <option selected={props.data['carReturnTime'] === '23:00'} value="23:00">23:00</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="detail-offer">
                <div className="row">
                    <div className="col-xl-6 form-group">
                        <label htmlFor="" className="form-label">Meno vodiča</label>
                        <input value={props.data['driverName']} onChange={(e) => props.onChange(e)}
                               className="form-control" type="text"
                               name='driverName'
                               ref={driverName}
                               placeholder="Meno" />
                    </div>
                    <div className="col-xl-6 form-group">
                        <label htmlFor="" className="form-label">Vek vodiča</label>
                        <input value={props.data['driverAge']} onChange={(e) => props.onChange(e)}
                               className="form-control" type="text"
                               name='driverAge'
                               ref={driverAge}
                               placeholder="Vek" />
                    </div>
                </div>
            </div>
        </>
    );

});

export default CarRent;
