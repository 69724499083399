import React, {Component} from 'react';
import ListItem from "./ListItem";
import Items from "./Items";
import Destination from "./Destination";
import Calendar from "./Calendar";
import Transport from "./Transport";
import Modal from "./Modal";

class Search extends Component {

	constructor(props) {
		super(props);
		this.state = {
			destinations: [],
			transport: [],
			open: false,
			typeModal: 'flightTicket',
			formData: {
				calendar: '',
				destination: '',
				transport: '',
				typeWay: 'Spiatočná'
			},
			error: false
		};
	}

	async componentDidMount() {
		await fetch('/api/data?type=data', {
			method: "GET",
		}).then(result => {
			return result.json();
		}).then(data => {
			let destinations = data['fieldsDef']['nl_destination_id']['options'];
			let transport = data['fieldsDef']['nl_transportation_id']['options'];

			this.setState(prevState => ({
				...prevState,
				destinations: destinations,
				transport: transport
			}));
		});
	}

	handleClick(type) {
		console.log(this.state.typeModal);
		let {calendar, destination, transport} = this.state.formData;

		if (this.state.typeModal == 'flightTicket' || this.state.typeModal == 'complex') {
			if (calendar && destination.length && transport.length) {
				this.setState(prevState => ({
					...prevState,
					open: true
				}))

				return;
			}
		}

		if (this.state.typeModal == 'accommodation') {
			if (calendar && destination.length) {
				this.setState(prevState => ({
					...prevState,
					open: true
				}))

				return;
			}
		}
		this.setState(prevState => ({
			...prevState,
			error: true
		}))

	}

	closeModal() {
		this.setState({
			open: false,
		}, () => {
			this.overflowBody(false);
		})
	}

	saveData(data, type) {
		this.setState(prevState => ({
			...prevState,
			formData: {
				...prevState.formData,
				[type]: data
			}
		}));
	}

	overflowBody(stateOpened) {


		let body = document.querySelector('body');
		let html = document.querySelector('html');
		if (stateOpened) {
			if (window.innerWidth < 765) {
				body.classList.add('overflow-hide');
				html.style.overflow = 'hidden';
				html.style.position = 'relative';
			}
		} else {
			body.classList.remove('overflow-hide');
			html.removeAttribute('style');
		}
	}

	changeType(type) {
		if (type == 'car') {
			this.setState({
				typeModal: type,
				open: true
			})
		} else {
			this.setState({
				typeModal: type,
			})
		}
	}

	render() {
		let {open, destinations, transport, formData, error, typeModal} = this.state;
		return (
			<div className="container">
				{
					((open || typeModal == 'car') &&
						<Modal
							open={this.state.open}
							close={() => this.closeModal()}
							data={formData}
							type={this.state.typeModal}
						/>
					)
				}
				<div className="tabs">
					<button className={typeModal == 'flightTicket' ? 'active' : ''}
					        onClick={() => this.changeType('flightTicket')}>
						{locale.flight_ticket}
					</button>
					<button className={typeModal == 'accommodation' ? 'active' : ''}
					        onClick={() => this.changeType('accommodation')}>
						{locale.accommodation}

					</button>
					<button className={typeModal == 'car' ? 'active' : ''} onClick={() => this.changeType('car')}>
						{locale.car}

					</button>
					<button className={typeModal == 'complex' ? 'active' : ''}
					        onClick={() => this.changeType('complex')}>
						{locale.complex}
					</button>
				</div>
				<h2>{locale.title}</h2>
				<div className={`${typeModal} content`}>
					<div className="form-group pointer-icon">
						<Destination
							error={!error || formData.destination.length > 0}
							data={destinations}
							saveData={(data) => this.saveData(data, 'destination')}
							overflowBody={(stateOpened) => this.overflowBody(stateOpened)}
						/>
					</div>
					<div className="form-group calendar-icon">
						<Calendar
							error={!error || formData.calendar}
							saveData={(data) => this.saveData(data, 'calendar')}
							overflowBody={(stateOpened) => this.overflowBody(stateOpened)}
						/>
					</div>
					{(typeModal != 'accommodation' &&
						<>
							<div className="form-group airplane-icon">
								<Transport
									error={!error || formData.transport.length > 0}
									data={transport}
									saveData={(data) => this.saveData(data, 'transport')}
									overflowBody={(stateOpened) => this.overflowBody(stateOpened)}
								/>
							</div>

							<div className="row">
								<div className="form-group">
									<input id="oneWay" name="type-way"
									       onClick={() => this.saveData('Jednosmerná', 'typeWay')}
									       checked={formData.typeWay == 'Jednosmerná'} value='one' type="radio" />
									<label htmlFor="oneWay">{locale.one_way}</label>
								</div>

								<div className="form-group">
									<input id="backWay" name="type-way"
									       onClick={() => this.saveData('Spiatočná', 'typeWay')}
									       checked={formData.typeWay == 'Spiatočná'} value='back' type="radio" />
									<label htmlFor="backWay">{locale.back_way}</label>
								</div>
							</div>
						</>
					)}

					<div className="buttons">

						<div className="form-group">
							<button onClick={() => this.handleClick('demand')}>{locale.want_offer}</button>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Search;
