import React, {Component} from 'react';
import Items from "./Items";

class Destination extends Component {

    constructor(props) {
        super(props);
        this.state = {
            destinations: [],
            checked: [],
            destinationsSearch: [],
            open: false
        };
        this.node = null;
        this.input = null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data !== prevState.data) {
            return {destinations: nextProps.data};
        } else return null;
    }

    setValues(e, itemKey) {
        let newValues = this.state.destinations;
        newValues[itemKey] = e;
        let selectedValues = this.renderSelectedValues(newValues);

        this.props.saveData(this.getFields(selectedValues, 'value'));
        this.setState(prevState => ({
            ...prevState,
            destinations: newValues,
            checked: selectedValues
        }));
    }

    toggleList() {
        this.setState(prevState => ({
            ...prevState,
            open: !prevState.open
        }), () => {
            this.props.overflowBody(this.state.open);
        })
    }


    componentDidMount() {
        document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    handleClickOutside(event) {
        if (this.node && !this.node.contains(event.target) && this.state.open) {
            this.setState(prevState => ({
                ...prevState,
                open: false
            }), () => {
                this.props.overflowBody(this.state.open);
            })
        }
    }


    renderSelectedValues(items, selected = []) {
        items.forEach((item) => {
            if (item.checked) {
                selected.push(item);
            } else {
                if (item['subOptions']) {
                    this.renderSelectedValues(item['subOptions'], selected);
                }
            }
        });
        return selected;
    }


    changeOnFalse(id, topItem) {
        if (topItem['id'] === id) {
            topItem['checked'] = false;
            this.changeOnFalse(null, topItem);
        } else {
            if (topItem['subOptions']) {
                topItem['subOptions'].forEach((item) => {
                    if (item.id === id || id === null) {
                        item['checked'] = false;
                        topItem['checked'] = false;
                        if (item['subOptions']) {
                            this.changeOnFalse(null, item);
                        }
                    } else {
                        if (item['subOptions']) {
                            this.changeOnFalse(id, item);
                        }
                    }
                });
                let allTrue = true;
                topItem['subOptions'].forEach((item) => {
                    if (!item['checked']) {
                        allTrue = false;
                        return;
                    }
                });
                if (!allTrue) {
                    topItem['checked'] = false;
                }
            }
        }

        return topItem;

    }

    removeItem(item) {
        let returnItems = this.changeOnFalse(item.id, item);
        this.setValues(returnItems);
    }

    getFields(input, field) {
        let output = [];
        for (let i = 0; i < input.length; ++i)
            output.push(input[i][field]);
        return output;
    }

    controllError(error) {
        if (this.input) {
            let node = this.input;
            if (!error) {
                node.classList.add('animated');
                node.classList.add('shake');

                function handleAnimationEnd() {
                    node.classList.remove('animated');
                    node.classList.remove('shake');
                    node.removeEventListener('animationend', handleAnimationEnd)
                }

                node.addEventListener('animationend', handleAnimationEnd)
            }
        }
    }

    async search(e) {
        let value = e.target.value;

        await fetch('/ajax/data.php?type=search&text=' + value, {
            method: "GET",
        }).then(result => {
            return result.json();
        }).then(data => {
            let search = [];
            if (data.found) {
                search = data.json[0]['options'];
            }

            this.setState(prevState => ({
                ...prevState,
                destinationsSearch: search
            }));
        });
    }

    loopSubOptions(id, sub, parentKey) {
        for (let i = 0; i < sub.length; i++) {
            let item = sub[i];
            if (item.id === id) {
                return true;
            }
            if (item['subOptions']) {
                if (this.loopSubOptions(id, item['subOptions'], parentKey)) {
                    return true;
                }
            }
        }
    }


    findParentItemKey(id, destinations) {
        for (let i = 0; i < destinations.length; i++) {
            let item = destinations[i];
            if (item.id === id) {
                return i;
            }
            if (item['subOptions']) {
                if (this.loopSubOptions(id, item['subOptions'], i))
                    return i;
            }
        }
    }


    render() {

        let {destinations, open, checked, destinationsSearch} = this.state;
        let destinationsItems = [];
        let map = destinationsSearch.length > 0 ? destinationsSearch : destinations;

        this.controllError(this.props.error);

        map.forEach((item, itemKey) => {
            let itemK = destinationsSearch.length > 0 ? this.findParentItemKey(item.id, destinations) : itemKey;
            destinationsItems.push(
                <Items
                    itemKey={itemKey}
                    desParentItem={destinations[itemK]}
                    setValues={(value) => this.setValues(value, itemK)}
                    item={item}
                    checked={checked}
                    changeOnFalse={(id, topItem) => this.changeOnFalse(id, topItem)}
                />
            );
        });


        return (
            <>
                <input
                    ref={ref => this.input = ref}
                    readOnly="readonly"
                    onFocus={() => this.toggleList()}
                    type="text"
                    className="form-control"
                    placeholder={locale.where}
                    value={this.getFields(checked, 'value').join(', ')}
                />
                <div ref={ref => this.node = ref} className={open ? 'list-items open wide' : 'list-items wide'}>
                    <div className="header-list">
                        <input onChange={(e) => this.search(e)} type="text" className="form-control"
                               placeholder={locale.where} />
                    </div>
                    <div className="body-list">
                        <ul>
                            {destinationsItems}
                        </ul>

                        <ul>
                            {checked && checked.map((item) => {
                                return (
                                    <li className="control-tag">
                                        <span>{item.value}</span>
                                        <button onClick={() => this.removeItem(item)}></button>
                                    </li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="footer-list">
                        <button onClick={() => this.toggleList()}>{locale.submit}</button>
                    </div>
                </div>
            </>
        );
    }
}

export default Destination;
