import React, {forwardRef, useImperativeHandle, useRef, useState} from 'react';

const CtaForm = forwardRef((props, ref) => {
	useImperativeHandle(
		ref,
		() => ({
			controlValid() {
				valid();
			}
		}),
	);

	const when = useRef();
	const time = useRef();

	const valid = () => {
		let valid = true;

		when.current.parentElement.classList.remove('error');
		time.current.parentElement.classList.remove('error');


		if (when.current.value == '') {
			when.current.parentElement.classList.add('error');
			valid = false;
		}

		if (time.current.value == '') {
			time.current.parentElement.classList.add('error');
			valid = false;
		}

		props.setValidForm(valid);
	}


	return (
		<div className="detail-offer">
			<div className="row">
				<div className="col-sm-6 form-group">
					<label htmlFor="when" className="form-label">Kedy</label>
					<input onChange={(e) => props.onChange(e)}
					       ref={when}
					       id="when"
					       name="when"
					       type="text"
					       value={props.data['when']}
					       className="form-control" />
				</div>

				<div className="col-sm-6 form-group">
					<label htmlFor="time" className="form-label">O
						koľkej</label>
					<input onChange={(e) => props.onChange(e)}
					       ref={time}
					       id="time"
					       name="time"
					       type="text"
					       value={props.data['time']}
					       className="form-control" />
				</div>
			</div>
		</div>
	);
});

export default CtaForm;