import React, {Component} from 'react';
import Items from "./Items";

class Transport extends Component {

    constructor(props) {
        super(props);
        this.state = {
            transport: [],
            open: false,
            checked: []
        }
        this.node = null;

    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.data !== prevState.data) {
            return {transport: nextProps.data};
        } else return null;
    }

    setValues(e, itemKey) {
        let newValues = this.state.transport;
        newValues[itemKey] = e;
        let selectedValues = this.renderSelectedValues(newValues);

        this.props.saveData(this.getFields(selectedValues, 'value'));
        this.setState(prevState => ({
            ...prevState,
            transport: newValues,
            checked: selectedValues
        }));

    }
    changeOnFalse(id, topItem) {
        if (topItem['id'] === id) {
            topItem['checked'] = false;
            this.changeOnFalse(null, topItem);
        } else {
            if (topItem['subOptions']) {
                topItem['subOptions'].forEach((item) => {
                    if (item.id === id || id === null) {
                        item['checked'] = false;
                        topItem['checked'] = false;
                        if (item['subOptions']) {
                            this.changeOnFalse(null, item);
                        }
                    } else {
                        if (item['subOptions']) {
                            this.changeOnFalse(id, item);
                        }
                    }
                });
                let allTrue = true;
                topItem['subOptions'].forEach((item) => {
                    if (!item['checked']) {
                        allTrue = false;
                        return;
                    }
                });
                if (!allTrue) {
                    topItem['checked'] = false;
                }
            }
        }

        return topItem;

    }

    renderSelectedValues(items, selected = []) {
        items.forEach((item) => {
            if (item.checked) {
                selected.push(item);
            } else {
                if (item['subOptions']) {
                    this.renderSelectedValues(item['subOptions'], selected);
                }
            }
        });
        return selected;
    }

    toggleList() {
        this.setState(prevState => ({
            ...prevState,
            open: !prevState.open
        }),()=>{
            this.props.overflowBody(this.state.open);
        })
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    handleClickOutside(event) {
        if (this.node && !this.node.contains(event.target) && this.state.open) {
            this.setState(prevState => ({
                ...prevState,
                open: false
            }),()=>{
                this.props.overflowBody(this.state.open);
            })
        }
    }

    getFields(input, field) {
        let output = [];
        for (let i = 0; i < input.length; ++i)
            output.push(input[i][field]);
        return output;
    }

    controllError(error) {
        if (this.input) {
            let node = this.input;
            if (!error) {
                node.classList.add('animated');
                node.classList.add('shake');
                function handleAnimationEnd() {
                    node.classList.remove('animated');
                    node.classList.remove('shake');
                    node.removeEventListener('animationend', handleAnimationEnd)
                }
                node.addEventListener('animationend', handleAnimationEnd)
            }
        }
    }


    render() {
        let {transport, open,checked} = this.state;


        this.controllError(this.props.error);


        let transportItems = [];
        transport.map((item, itemKey) => {
            transportItems.push(
                <Items
                    itemKey={itemKey}
                    desParentItem={item}
                    setValues={(value) => this.setValues(value, itemKey)}
                    item={item}
                    readOnly="true"
                    checked={checked}
                    changeOnFalse={(id, topItem) => this.changeOnFalse(id, topItem)}

                />
            );
        });
        return (
            <>
                <input
                    ref={ref => this.input = ref}
                    onFocus={() => this.toggleList()}
                    type="text"
                    readOnly="readonly"
                    className="form-control animated"
                    placeholder={locale.from}
                    value={this.getFields(checked, 'value').join(', ')}
                />
                <div  ref={ref => this.node = ref} className={open ? 'list-items open' : 'list-items'}>
                    <div className="header-list">
                        <input readOnly="readonly" type="text" className="form-control" placeholder={locale.from} />
                    </div>
                    <div className="body-list">
                        <ul>
                            {transportItems}
                        </ul>

                    </div>
                    <div className="footer-list">
                        <button onClick={() => this.toggleList()}>{locale.submit}</button>
                    </div>
                </div>
            </>
        );
    }
}

export default Transport;
