import React, {Component} from 'react';
import Modal from "./Modal";

class Cta extends Component {
	constructor(props) {
		super(props);

		const DEFAULT_VALUE = props.locale == 'sk' ? '+421' : '+420';

		this.state = {
			type: 'cta-call',
			open: false,
			value: DEFAULT_VALUE
		};

	}

	handleClick() {
		this.setState({
			open: true
		})
	}

	closeModal() {
		this.setState({
			open: false
		})
	}

	onChange(e) {
		let value = e.target.value;

		this.setState({
			value: value
		})
	}

	callMe() {
		if (this.state.value !== DEFAULT_VALUE && this.state.value.length > 4) {


			let formData = new FormData();
			formData.append('data', JSON.stringify({phone: this.state.value, type: this.state.type}));

			fetch('/api/send-contact-form', {
				'method': 'POST',
				body: formData,
				processData: false,
				contentType: false,
				dataType: 'json'
			}).then(result => {
				return result.json();
			}).then(data => {
				let msg = data.msg;
				let code = data.code;
				if (code == 1) {
					alert('Ďakujeme, budeme Vás kontaktovať.');
					this.setState({
						'value': DEFAULT_VALUE
					})
				} else {
					alert(msg);
				}
			});

		} else {
			alert('Vyplňte telefonné číslo!');
		}
	}

	render() {
		const {value} = this.state;
		return (
			<div className="container">
				{
					((this.state.open) &&
						<Modal
							open={this.state.open}
							close={() => this.closeModal()}
							data={[]}
							type="cta"
						/>
					)}
				<div className="content">
					<button onClick={() => this.handleClick()}>{FULLLOCALE.order_button}</button>
					<p dangerouslySetInnerHTML={{__html: FULLLOCALE.order_text}}></p>
					<div className="form-group">
						<input placeholder={FULLLOCALE.input_phone} type="text" value={value}
						       onChange={(e) => this.onChange(e)} />
						<button onClick={() => this.callMe()}>{FULLLOCALE.send}</button>
					</div>
				</div>
			</div>
		);
	}
}

export default Cta;