import React, {Component} from 'react';

class ListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {item,checked} = this.props;

        return (
            <>
                <label htmlFor={item.id} className={checked ? 'input-icon checked' : 'input-icon'}></label>
                <input
                    checked={checked}
                    onChange={(e) => this.props.onChange(e)}
                    type="checkbox"
                    id={item.id}
                    value={item.value}
                />

                <label htmlFor={item.id}>{item.value}</label>
            </>
        );
    }
}

export default ListItem;