import React, {useRef, useImperativeHandle, forwardRef} from 'react';

const FlightTicket = forwardRef((props, ref) => {
	useImperativeHandle(
		ref,
		() => ({
			controlValid() {
				valid();
			}
		}),
	);

	const adults = useRef();

	const valid = () => {
		let valid = true;

		adults.current.parentElement.classList.remove('error');
		if (adults.current.value == '0') {
			adults.current.parentElement.classList.add('error');
			valid = false;
		}

		props.setValidForm(valid);
	}

	return (
		<div>
			<div className="detail-offer">
				<input type="hidden" name="destination"
				       value={props.data.destination.join(', ')} />
				<input type="hidden" name="calendar"
				       value={props.data.calendar['from'] + "-" + props.data.calendar['to']} />
				<input type="hidden" name="transport"
				       value={props.data.transport.join(', ')} />


				<p><strong>Kam to bude: </strong>{props.data.destination.join(', ')}</p>
				<p>
					<strong>Termín: </strong>{props.data.calendar['from']} - {props.data.calendar['to']}
				</p>
				<p><strong>Typ dopravy: </strong>{props.data.transport.join(', ')} |
					{props.data.typeWay}</p>

				<div className="form-group">
					<label className="form-label" htmlFor="">Cieľové letisko</label>
					<input className="form-control" type="text" name="destination_airport"
					       onChange={(e) => props.onChange(e)} />
				</div>
				<div className="form-group">
					<label htmlFor="adults" className="form-label">Počet dospelých osôb</label>
					<select ref={adults} onChange={(e) => props.onChange(e)} className="form-control" name="adults"
					        id="adults">
						<option value="0" selected>Vyberte</option>
						<option selected={props.data['adults'] == '1'} value="1">1</option>
						<option selected={props.data['adults'] == '2'} value="2">2</option>
						<option selected={props.data['adults'] == '3'} value="3">3</option>
						<option selected={props.data['adults'] == '4'} value="4">4</option>
						<option selected={props.data['adults'] == '5'} value="5">5</option>
						<option selected={props.data['adults'] == '6'} value="6">6</option>
						<option selected={props.data['adults'] == '7'} value="7">7</option>
						<option selected={props.data['adults'] == '8'} value="8">8</option>
						<option selected={props.data['adults'] == '9'} value="9">9</option>
						<option selected={props.data['adults'] == '10'} value="10">10</option>
					</select>
					<span className="warning-text">Toto pole je povinné</span>
				</div>
				<div className="row">
					<div className="form-group col-xl-6">
						<label htmlFor="babies" className="form-label">Počet detí do 2 rokov:</label>
						<select required onChange={(e) => props.onChange(e)} className="form-control" name="babies"
						        id="babies">
							<option value="0" selected>Vyberte</option>
							<option selected={props.data['babies'] == '1'} value="1">1</option>
							<option selected={props.data['babies'] == '2'} value="2">2</option>
							<option selected={props.data['babies'] == '3'} value="3">3</option>
							<option selected={props.data['babies'] == '4'} value="4">4</option>
							<option selected={props.data['babies'] == '5'} value="5">5</option>
							<option selected={props.data['babies'] == '6'} value="6">6</option>
							<option selected={props.data['babies'] == '7'} value="7">7</option>
							<option selected={props.data['babies'] == '8'} value="8">8</option>
							<option selected={props.data['babies'] == '9'} value="9">9</option>
							<option selected={props.data['babies'] == '10'} value="10">10</option>
						</select>
					</div>


					<div className="form-group col-xl-6">
						<label htmlFor="childs" className="form-label">Počet detí od 2 rokov do 14 rokov:</label>
						<select required onChange={(e) => props.onChange(e)} className="form-control" name="childs"
						        id="childs">
							<option value="0" selected>Vyberte</option>
							<option selected={props.data['childs'] == '1'} value="1">1</option>
							<option selected={props.data['childs'] == '2'} value="2">2</option>
							<option selected={props.data['childs'] == '3'} value="3">3</option>
							<option selected={props.data['childs'] == '4'} value="4">4</option>
							<option selected={props.data['childs'] == '5'} value="5">5</option>
							<option selected={props.data['childs'] == '6'} value="6">6</option>
							<option selected={props.data['childs'] == '7'} value="7">7</option>
							<option selected={props.data['childs'] == '8'} value="8">8</option>
							<option selected={props.data['childs'] == '9'} value="9">9</option>
							<option selected={props.data['childs'] == '10'} value="10">10</option>
						</select>
					</div>
				</div>


			</div>
		</div>
	);

});

export default FlightTicket;
