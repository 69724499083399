import React, {Component, useState, useEffect, useRef} from 'react';
import ReactDOM from 'react-dom';
import FlightTicket from "./FlightTicket";
import ContactData from "./ContactData";
import Accommodation from "./Accommodation";
import Complex from "./Complex";
import CarRent from "./CarRent";
import CtaForm from "./CtaForm";

const Modal = (props) => {
	const [valid, setValid] = useState(true);
	const [data, setData] = useState({
		...props.data,
		type: props.type,
	})
	const contactForm = useRef();
	const typeFormRef = useRef();
	const el = useRef(document.createElement('div'));
	const modalRoot = document.getElementById('modals');

	useEffect(() => {
		modalRoot.appendChild(el.current);
		return () => {
			modalRoot.removeChild(el.current);
		}
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();




		let validNow = true;

		if (validNow && valid) {

			let formData = new FormData();
			formData.append('data', JSON.stringify(data));

			fetch('/api/send-contact-form', {
				'method': 'POST',
				body: formData,
				processData: false,
				contentType: false,
				dataType: 'json'
			}).then(result => {
				return result.json();
			}).then(data => {
				let msg = data.msg;
				let code = data.code;
				if (code == 1) {
					alert('Zaznamenali sme Vašu nezáväznú ponuku, budeme Vás kontaktovať.');
					window.location.reload();
					contactForm.current.reset();
					props.close();
				} else {
					alert(msg);
				}
			})
		} else {
			setValid(true);
		}
	}

	const setValidForm = (valid) => {
		if (!valid) {
			setValid(false);
		}
	}

	const overflowBody = (stateOpened) => {
		let body = document.querySelector('body');
		let html = document.querySelector('html');

		if (stateOpened) {
			body.classList.add('overflow-hide');
			html.style.overflow = 'hidden';
			html.style.position = 'relative';
		} else if (body.classList.contains('overflow-hide')) {
			body.classList.remove('overflow-hide');
			html.removeAttribute('style');
		}
	}

	const onChange = (dataOnChange) => {
		let type = dataOnChange.name;
		let value = dataOnChange.value;
		if (dataOnChange.target) {
			type = dataOnChange.target.name;
			value = dataOnChange.target.value;
		}

		setData({
			...data,
			[type]: value
		})
	}

	console.log(data);
	if (props.open) {
		return ReactDOM.createPortal(
			<div className="modal">
				<div className="wrap">
					<div className="container">
						<div className="content">
							<div className="header">
								<button onClick={() => props.close()} className="close"></button>

								<h2>{data.type === 'cta' ? "Objednať sa na termín" : "Chcem nezáväznú ponuku"}</h2>
							</div>
							<div className="body">
								<form action="POST" onSubmit={(e) => handleSubmit(e)}
								      ref={contactForm}>


									{
										(data.type == 'cta' &&
											<>
												<CtaForm
													ref={typeFormRef}
													setValidForm={(valid) => setValidForm(valid)}
													onChange={(data) => onChange(data)}
													data={data}
												/>
												<ContactData
													data={data}
													onClick={typeFormRef}
													setValidForm={(valid) => setValidForm(valid)}
													onChange={(data) => onChange(data)}
												/>
											</>
										)
									}


									{
										(data.type == 'flightTicket' &&
											<>
												<FlightTicket
													ref={typeFormRef}
													setValidForm={(valid) => setValidForm(valid)}
													onChange={(data) => onChange(data)}
													data={data}
												/>
												<ContactData
													data={data}
													onClick={typeFormRef}
													setValidForm={(valid) => setValidForm(valid)}
													onChange={(data) => onChange(data)}
												/>
											</>
										)
									}

									{
										(data.type == 'accommodation' &&
											<>
												<Accommodation
													ref={typeFormRef}
													setValidForm={(valid) => setValidForm(valid)}
													onChange={(data) => onChange(data)}
													data={data} />
												<ContactData
													data={data}
													onClick={typeFormRef}
													setValidForm={(valid) => setValidForm(valid)}
													onChange={(data) => onChange(data)}
												/>
											</>
										)
									}

									{
										(data.type == 'car' &&
											<>
												<CarRent
													ref={typeFormRef}
													setValidForm={(valid) => setValidForm(valid)}
													onChange={(data) => onChange(data)}
													data={data} />
												<ContactData
													data={data}
													onClick={typeFormRef}
													setValidForm={(valid) => setValidForm(valid)}
													onChange={(data) => onChange(data)}
												/>
											</>
										)
									}

									{
										(data.type == 'complex' &&
											<Complex
												onChange={(data) => onChange(data)}
												setValidForm={(valid) => setValidForm(valid)}
												data={data} />
										)
									}
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>,
			el.current
		);
	} else {
		return (<></>);
	}

}

export default Modal;
