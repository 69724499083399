import React, {Component} from 'react';
import moment from 'moment'

import 'react-dates/initialize'
import {DayPickerRangeController} from 'react-dates'
import 'react-dates/lib/css/_datepicker.css'
import 'moment/locale/sk';


class Calendar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            startDate: null,
            endDate: null,
            focusedInput: 'startDate'
        };

        this.node = null;
    }


    toggleList() {
        this.setState(prevState => ({
            ...prevState,
            open: !prevState.open,
            focusedInput: 'startDate'
        }), () => {
            this.props.overflowBody(this.state.open);
        })
    }

    changeFocusedInput(focusedInput) {
        if (focusedInput) {
            this.setState(prevState => ({
                ...prevState,
                focusedInput: focusedInput
            }));
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', (e) => this.handleClickOutside(e));
    }

    handleClickOutside(event) {
        if (this.node && !this.node.contains(event.target) && this.state.open) {
            this.setState(prevState => ({
                ...prevState,
                open: false
            }), () => {
                this.props.overflowBody(this.state.open);
            });
        }
    }

    changeDates(e) {
        this.props.saveData({
            'from': e.startDate ? moment(e.startDate).format("DD.MM.YYYY") : "",
            'to': e.endDate ? moment(e.endDate).format("DD.MM.YYYY") : ""
        });
        this.setState(e);
    }

    controllError(error) {
        if (this.input) {
            let node = this.input;
            if (!error) {
                node.classList.add('animated');
                node.classList.add('shake');

                function handleAnimationEnd() {
                    node.classList.remove('animated');
                    node.classList.remove('shake');
                    node.removeEventListener('animationend', handleAnimationEnd)
                }

                node.addEventListener('animationend', handleAnimationEnd)
            }
        }
    }


    render() {
        const {open, startDate, endDate, focusedInput} = this.state;
        let win = window;
        moment.locale('sk');

        this.controllError(this.props.error);


        return (
            <>
                <input
                    ref={ref => this.input = ref}
                    readOnly="readonly"
                    onClick={() => this.toggleList()}
                    type="text"
                    className="form-control animated"
                    placeholder={locale.when_to}
                    value={(startDate ? moment(startDate).format("DD.MM.YYYY") + " - " : "") + (endDate ? moment(endDate).format("DD.MM.YYYY") : "")}
                />
                <div ref={ref => this.node = ref} className={open ? 'list-items open' : 'list-items'}>
                    <div className="header-list">
                        {/*<input type="text" className="form-control" placeholder={locale.when_to} />*/}
                    </div>
                    <div className="body-list calendar-body-list">
                        <div className="callendar-wrapper">
                            <div className="inputs">
                                <div
                                    className={focusedInput === 'startDate' ? 'input-wrapper input-wrapper-active' : 'input-wrapper'}>
                                    <div className="form-group">
                                        <input className="form-control"
                                               onFocus={() => this.setState({focusedInput: 'startDate'})}
                                               type="text"
                                               id="startDate"
                                               name="startDate"
                                               placeholder={locale.when_to}
                                               autoComplete="off"
                                               value={startDate ? moment(startDate).format("DD.MM.YYYY") : ""} />
                                    </div>
                                    <div className="triangel"></div>

                                </div>
                                <div
                                    className={focusedInput === 'endDate' ? 'input-wrapper input-wrapper-active' : 'input-wrapper'}>
                                    <div className="form-group">
                                        <input className="form-control"
                                               onFocus={() => this.setState({focusedInput: 'endDate'})}
                                               type="text"
                                               id="endDate"
                                               name="endDate"
                                               placeholder={locale.when_back}
                                               autoComplete="off"
                                               value={endDate ? moment(endDate).format("DD.MM.YYYY") : ""} />
                                        <div className="triangel"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="range-controller-wrapper">
                                <DayPickerRangeController
                                    startDate={startDate}
                                    endDate={endDate}
                                    noBorder={true}
                                    numberOfMonths={2}
                                    focusedInput={focusedInput}
                                    orientation={win.innerWidth < 765 ? 'verticalScrollable' : 'horizontal'}
                                    isMobile={true}
                                    renderCalendarInfo={false}
                                    autoFocusEndDate={false}
                                    onFocusChange={(focusedInput) => this.changeFocusedInput(focusedInput)}
                                    onDatesChange={(e) => this.changeDates(e)}
                                    renderMonthText={month => moment(month).locale('sk').format('MMMM YYYY')}
                                    renderDayContents={day => moment(day).locale('sk').format('D')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="footer-list">
                        <button onClick={() => this.toggleList()}>{locale.submit}</button>
                    </div>
                </div>
            </>
        );
    }
}

export default Calendar;
