import React, {useRef, useState} from 'react';

import FlightTicket from "./FlightTicket";
import Accommodation from "./Accommodation";
import ContactData from "./ContactData";
import CarRent from "./CarRent";

const Complex = ({data, setValidForm, onChange}) => {
    const [page, setPage] = useState('flight');
    const typeFormRef = useRef();


    return (
        <div>
            {(page == 'flight' &&
                <>
                    <FlightTicket
                        ref={typeFormRef}
                        setValidForm={(valid) => {
                            if (valid)
                                setPage('accommodation');
                        }}
                        onChange={(data) => onChange(data)}
                        data={data}
                    />
                    <div className="text-center">
                        <button
                            className="modal-btn"
                            onClick={() => {
                                typeFormRef.current.controlValid();
                            }}
                            type="button">
                            Uložiť a prejsť na ubytovanie
                        </button>
                    </div>
                </>
            )}
            {(page == 'accommodation' &&
                <>
                    <Accommodation
                        ref={typeFormRef}
                        setValidForm={(valid) => {
                            if (valid)
                                setPage('car');
                        }}
                        onChange={(data) => onChange(data)}
                        data={data}
                    />
                    <div className="text-center">
                        <button
                            className="modal-btn"
                            onClick={() => {
                                typeFormRef.current.controlValid();
                            }}
                            type="button">
                            Uložiť a prejsť na požičanie auta
                        </button>
                    </div>
                </>
            )}

            {(page == 'car' &&
                <>
                    <CarRent
                        ref={typeFormRef}
                        setValidForm={(valid) => setValidForm(valid)}
                        onChange={(data) => onChange(data)}
                        data={data}
                    />
                    <ContactData
                        onChange={(data) => onChange(data)}
                        data={data}
                        onClick={typeFormRef}
                        setValidForm={(valid) => setValidForm(valid)}
                    />
                </>
            )}

        </div>
    );

};

export default Complex;
