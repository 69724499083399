import React, {useRef, useState} from 'react';
import ContactData from "./ContactData";

const ContactForm = (props) => {

	const [valid, setValid] = useState(true);
	const [data, setData] = useState({
		type: 'offer'
	})
	const contactForm = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();

		let validNow = true;

		if (validNow && valid) {

			let formData = new FormData();
			formData.append('data', JSON.stringify(data));


			fetch('/api/send-contact-form', {
				'method': 'POST',
				body: formData,
				processData: false,
				contentType: false,
				dataType: 'json'
			}).then(result => {
				return result.json();
			}).then(data => {
				let msg = data.msg;
				let code = data.code;
				if (code == 1) {
					alert('Zaznamenali sme Vašu nezáväznú ponuku, budeme Vás kontaktovať.');
					contactForm.current.reset();
					setData({
						type: 'offer'
					})
					close();
				} else {
					alert(msg);
				}
			})
		} else {
			setValid(true);
		}
	}

	const setValidForm = (valid) => {
		if (!valid) {
			setValid(false);
		}
	}

	const close = () => {
		console.log(data);
		document.getElementById('contact-form').classList.remove('open');
	}

	const onChange = (dataOnChange) => {
		let type = dataOnChange.name;
		let value = dataOnChange.value;
		if (dataOnChange.target) {
			type = dataOnChange.target.name;
			value = dataOnChange.target.value;
		}

		setData({
			...data,
			[type]: value
		})
	}


	return (
		<div className="modal">
			<div className="wrap">
				<div className="container">
					<div className="content">
						<div className="header">
							<button onClick={() => close()} className="close"></button>

							<h2>Chcem nezáväznú ponuku</h2>
						</div>
						<div className="body">
							<form action="POST" onSubmit={(e) => handleSubmit(e)} ref={contactForm}>
								<ContactData
									data={data}
									onChange={(data) => onChange(data)}
									setValidForm={(valid) => setValidForm(valid)} />
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactForm;